/* #chart {
    position: relative;
    text-align: center;
} */

#center-text {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    margin-top: 15px;
}

.apexcharts-legend {
    display: flex;
    flex-direction: column; /* Align legends vertically */
    align-items: flex-start; /* Align legends to the start of the container */
  }